<template>
  <div class="info">
    <top-bar :title="!submitData.id?'新增汽充':edit?'修改汽充':'查看汽充'" :left="true"></top-bar>
    <!-- 所在小区 -->
    <van-popup v-model="subareaShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="name"
                  @confirm="subareaConfirm" @cancel="subareaShow = false"/>
    </van-popup>
    <!-- 巡查时间 -->
    <van-popup v-model="installDateShow" position="bottom" >
      <van-datetime-picker v-model="submitData.installDate" type="date" title="请选择" :formatter="formatDate"
                            @cancel="installDateShow = false" @confirm="timeConfirm"/>
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">汽充信息</span>
        </template>
      </van-cell>
      <van-cell  required title="车主">
        <van-field v-model="submitData.carOwner" clearable clear-trigger="always" placeholder="请输入车主姓名" :readonly="!edit"/>
      </van-cell>
      <van-cell title="所在小区" :value="selectMsg.subareaStr" @click="subareaShow = edit" :clickable="edit"
                :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="edit" />
      <van-cell required title="安装地址">
        <van-field v-model="submitData.installPlace" clearable clear-trigger="always" placeholder="请输入安装地址" :readonly="!edit"/>
      </van-cell>
      <van-cell required title="电表户号">
        <van-field v-model="submitData.ammeterNumber" clearable clear-trigger="always"  placeholder="请输入电表户号" :readonly="!edit"/>
      </van-cell>
      <van-cell  required title="联系电话">
        <van-field v-model="submitData.mobile" clearable clear-trigger="always" placeholder="请输入联系电话" :readonly="!edit"/>
      </van-cell>
      <van-cell  required  title="车型,例:奥迪A8">
        <van-field v-model="submitData.carType" clearable clear-trigger="always" placeholder="请输入车型" :readonly="!edit"/>
      </van-cell>
      <van-cell title="自用桩品牌" required >
        <van-field v-model="submitData.chargeType" clearable clear-trigger="always" placeholder="请输入自用桩品牌" :readonly="!edit"/>
      </van-cell>
      <van-cell required title="自用桩数量">
        <van-field v-model="submitData.chargeCount" clearable clear-trigger="always" placeholder="请输入自用桩数量" :readonly="!edit"/>
      </van-cell>
      <van-cell title="安装日期" :value="selectMsg.installDate" @click="installDateShow = edit"
                :value-class="{'value-common':selectMsg.installDate=='请选择'}" is-link />
      <van-cell title="是否需要定期检查">
        <van-switch v-model="submitData.needCheck" size="24px" />
      </van-cell>
      <van-cell title="检查周期" error v-if="submitData.needCheck">
        <van-field v-model="submitData.checkCycle" clearable clear-trigger="always" placeholder="请输入天" :readonly="!edit"/>
      </van-cell>
      <van-cell required title="功率" :border="false">
        <van-field v-model="submitData.power" clearable clear-trigger="always" placeholder="请输入功率" :readonly="!edit"/>
      </van-cell>
      <van-row>
        <van-col :span="24">
          <van-uploader v-model="fileList"  @delete="uploaddel" :after-read="uploadRead" :deletable="edit"
                        :show-upload="edit" :before-read="beforeUpload" :max-count="1"/>
        </van-col>
      </van-row>
    </van-cell-group>
    <van-row class="btns">
      <van-col :span="24" v-if="edit">
        <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
      </van-col>
      <van-row gutter="10" v-else>
        <van-col :span="24">
          <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDate,newDate} from "@/utils/utils"
import {getImageStream} from '@/utils/index'
import { getDictTree } from '@/utils/common.js'
import placeList from "../visit/placeList"

export default {
  data() {
    return {
      i: '',
      edit: false,
      file: {},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      addrShow: false,
      subareaShow: false,
      inspectionTypeShow: false,
      installDateShow: false,
      selectMsg: {
        subareaStr: '请选择',
        inspectionTypeStr: '请选择',
        installDate: '请选择'
      },
      submitData: {
        id: '',
        //车主
        carOwner:'',
        //居住地址
        resideTitle:'',
        //小区
        subarea:'',
        //楼幢
        building:'',
        //单元
        unit:'',
        //房屋
        house:'',
        //安装地址
        installPlace:'',
        //联系电话
        mobile:'',
        //电表户号
        ammeterNumber:'',
        //安装日期
        installDate:'',
        //是否需要定期检查
        needCheck:false,
        //检查周期
        checkCycle:'',
        //车型
        carType:'',
        //自用桩品牌
        chargeType:'',
        //自用桩数量
        chargeCount:'',
        //功率
        power:'',
        fileList: [],
        newFileMessages: [],
        deleteFiles: [],
        type:''
      },
      selectData: {
        subareaList: [],
      },
      type:'',
      fileList: [],
    }
  },
  components: {
    topBar,
    placeList
  },
  methods: {
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
      if (file.id !== 0) {
        this.submitData.deleteFiles.push(file.id)
      }
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/device/carChargePicture')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            this.relativePath = data.fileMessage.relativePath
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            this.submitData.newFileMessages.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 初始化
    init() {
      this.getSubareaList()
      let nowDate = new Date()
      this.maxDate = nowDate
      this.selectMsg.installDate = formatterDate(nowDate)
      this.submitData.installDate = nowDate
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }

    },
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.subareaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/device/info/'+this.submitData.id),
        method: 'post',
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.carOwner = data.deviceInfo.charger
          this.submitData.subarea = data.deviceInfo.subarea
          this.selectMsg.subareaStr = data.deviceInfo.subareaStr
          this.submitData.installPlace = data.deviceInfo.address
          this.submitData.mobile = data.deviceInfo.mobile
          this.submitData.ammeterNumber = data.deviceInfo.coding
          this.submitData.installDate = data.deviceInfo.locationDate
          this.submitData.needCheck = data.deviceInfo.needCheck == '1' ? true : false
          this.submitData.checkCycle = data.deviceInfo.checkCycle
          this.submitData.carType = data.deviceInfo.extendedList[0].value
          this.submitData.chargeType = data.deviceInfo.extendedList[1].value
          this.submitData.chargeCount = data.deviceInfo.extendedList[2].value
          this.submitData.power = data.deviceInfo.extendedList[3].value
          let u
          u = getImageStream(data.deviceInfo.extendedList[4].value)
          this.relativePath = data.deviceInfo.extendedList[4].value
          this.selectMsg.installDate = data.deviceInfo.locationDate
          let url = {
            uid: data.deviceInfo.extendedList[4].value.length,
            id: 1,
            status: 'done',//uploading上传中，done上传完成，error上传失败
            // fileType: file.type,
            url: u,
            realUrl: data.deviceInfo.extendedList[4].value.replace(/\\/g, "/")
          }
          this.fileList.push(url)
          //let url = data.deviceInfo.extendedList[4].value
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 提交
    submit() {
      if (this.submitData.carOwner === '') {
        return this.$toast.fail('失败:请输入车主姓名');
      }
      if (this.submitData.subarea === '') {
        return this.$toast.fail('失败:小区不能为空');
      }
      if (this.submitData.installPlace === '') {
        return this.$toast.fail('失败:安装地址不能为空');
      }
      if (this.submitData.ammeterNumber === '') {
        return this.$toast.fail('失败:电表户号不能为空');
      }
      if (this.submitData.mobile === '') {
        return this.$toast.fail('失败:手机号码不能为空');
      }
      if (this.submitData.carType === '') {
        return this.$toast.fail('失败:车型不能为空');
      }
      if (this.submitData.chargeType === '') {
        return this.$toast.fail('失败:自用桩品牌不能为空');
      }
      if (this.submitData.chargeCount === '') {
        return this.$toast.fail('失败:自用桩数量不能为空');
      }

      if (this.submitData.power === '') {
        return this.$toast.fail('失败:功率不能为空');
      }
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/device/${this.submitData.id ? 'update' : 'save'}`),
        method: 'post',
        data: {
          id: this.submitData.id,
          orgId: this.submitData.subarea,
          name:'汽车充电桩',
          address:this.submitData.installPlace,
          type:this.type,
          coding:this.submitData.ammeterNumber,
          locationDate:this.submitData.installDate,
          mobile:this.submitData.mobile,
          charger:this.submitData.carOwner,
          needCheck:this.submitData.needCheck ? 1 : 0,
          checkCycle:this.submitData.checkCycle,
          extended:`[{"key": "carType","labelName": "车型","value": "${this.submitData.carType}"},
          {"key": "brand","labelName": "自用桩品牌","value":"${this.submitData.chargeType}" },
          {"key": "stakeNum","labelName": "自用桩数量","value": "${this.submitData.chargeCount}"},
          {"key": "stakePower","labelName": "功率","value": "${this.submitData.power}"},
          {"key": "stakePhoto","labelName": "充电桩照片","value": "${this.relativePath}"}]`,
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = value.name
        this.submitData.subarea = value.id
      }
      this.subareaShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      // if (type === 'hour') {
      //   return val + '时'
      // }
      // if (type === 'minute') {
      //   return val + '分'
      // }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.installDate = formatterDate(value)
        this.submitData.installDate = formatterDate(value)
      }
      this.installDateShow = false
    },
    getDeviceTypeList () {
      let that = this;
      getDictTree({ code: "deviceType" }, function (e) {
        e.forEach((item) => {
          if(item.label == "汽车充电桩"){
            that.type = item.value;
          }
        });
        console.log(that.type)
      });
    },

    // getIdsList(ids, index, arr, list) {
    //   let i = arr.findIndex(item => ids[index] == item.value)
    //   if (i !== -1) {
    //     list.push(arr[i])
    //   }
    //   if (arr[i].children && ids[index++]) {
    //     this.getIdsList(ids, index++, arr[i].children, list)
    //   }
    // },
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
    this.getDeviceTypeList()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
